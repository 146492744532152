import React from "react";

import SimpleLanding from "../../../layouts/simple-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import { Link } from "@reach/router";

const ThankYou = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-line-of-credit",
      title: "Credit Solutions"
    },
    {
      id: 3,
      active: true,
      title: "Thank You!"
    }
  ];

  const SEOData = {
    title: "Small Business Thank You",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Small Business Thank You"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "WaFd Bank puts your business first. Whether you need a business line of credit or commercial real estate loan, we can help. Learn more here."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-line-of-credit/thank-you"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <p>
          Thank you for contacting WaFd Bank for your business lending needs. Your application is under review and one
          of our local, experienced Community Bankers with be reaching out for next steps in the process. Please expect
          to be contacted by one of our bankers via phone or email in no more than 2 business days.
        </p>
        <p>
          <Link to="/business-banking/business-line-of-credit">Back to Credit Solutions</Link>
        </p>
      </section>
    </SimpleLanding>
  );
};

export default ThankYou;
